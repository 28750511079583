import React from 'react'; 
// import { Navbar, Nav, Form, FormControl, Button, ListGroup} from 'react-bootstrap';
import  './Home.css';
// import Card from '../Card/Card';
// import AI from '../assets/img/banner/card.jpg';

const home = () => (

<section id="home" className="d-flex align-items-center">

    <div className="container">
      <div className="row">
        <div className="col-lg-8 d-flex flex-row justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" >
          <div className="text">We provide <b>IT and Digital Transformation services  </b> 
            {/* to help clients take <span style={{ fontSize: "2.5vw" }}><br />data driven decisions.</span></div> */}
            Enabling Cloud Platforms and Building Big Data and Machine learning Solutions. <span style={{ fontSize: "2.5vw" }}><br /></span>

          </div>

        </div>
      </div>
    </div>
  </section>

  
  );
export default home;
import React, {useState} from 'react';
import Card from '../Card/Card';
// import AI from '../assets/img/banner/card.jpg';
import cloud from '../assets/img/data_engineering.png';
import ba from '../assets/img/business_analytics.jpg';
import ds from '../assets/img/data_science.jpg';
import { Modal } from 'react-bootstrap';
// import classes from './service.css';

     const data = [
        {
            title : "Data Engineering",
            img : cloud,
            services: [
                {
                title : 'Cloud Strategies & Architecture',
                description : 'We help organizations to migrate their data assets and analytics tools to the cloud with minimal disruption to the business.'

                },
                {
                title : 'Data Engineering & Pipelines',
                description :  'We build scalable and production-grade end-to-end data pipelines, data lakes, and platforms. Data engineering enables value realization to reduce operational costs, discover new revenue sources and create new products, also helps data engineers and scientists to reduce the time needed to productionize a model.'
                },
                {
                title : 'Business Intelligence',
                description : 'With our well-designed intelligent solutions, we enable you to unleash the power of your data to grow, innovate, and beat out the competitiontion.'
                }
            ]
        },
        {
            title : "Data Science",
            img : ds,
            services: [
                {
                    title : ' AI & ML Predictive Models',
                    description : 'At Tiger Vector, We build and deploy advanced machine learning and AI-driven solutions to derive value from complex and big data generated by customers or services, real-time events, and IoT sensor data.'
                },
                {
                   title : 'Deep & Shallow Models ',
                   description :  'With deep learning models, we enable you to achieve state-of-the-art accuracy, sometimes exceeding human-level performance'
               },
               {
                   title : 'TensorFlow & TFX ML Pipelines',
                   description : 'We are one of the leading AI & Machine Learning development company, who are well versed in integrating TensorFlow with your application to identify people, face, image, and recognize objects and building robust ML pipelines.'
               }
            ]
        },
        {
            title : "Business Analytics",
            img : ba,
            services: [
                {
                    title : 'KPI Reporting & Visualization',
                    description : 'KPIs are the most important business metrics for an organization or industry. By grouping all of the KPIs together, you can visualize the industry-specific metrics, which will allow you to analyze the business data and reach concrete and helpful insights.'
                },
                {
                   title : 'Data Insight Discovery',
                   description :  'We help you to develop a plan to better use your data and analytics to recover from a recession by extracting key insights that lead to effective business decisions.'
               },
               {
                   title : 'Monitoring Dashboards',
                   description : 'Our intelligent alerting & monitoring dashboard based on artificial intelligence learns trends and patterns from data and automatically notifies you as soon as something important or unusual happens'
               }
            ]
        }
     ]



const DataEngineering = (props) => {
    const [show, setShow] = useState(false);
    const [heading, setHeading] = useState("");
    const [description, setDescription] = useState("");
   
    const handleClose = () => setShow(false);
    const handleShow = (heading, description) =>{
        setHeading(heading);
        setDescription(description)
        setShow(true);
    }
    return(
<>
    <section id="services" className="section"  >
      <div className="container" >

        <div className="section-title deco"><br/><br/>
          <h2>Our Services</h2>
        </div>
        <p className="section-title ">Exclusive artificial intelligence and machine
              learning related product and service offerings..</p>
          
          <div className="rowds">
              {data.map((card) =>
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" key = "">
                    <Card title={card.title} list={card.services.map(service=> <p className="servicepara" key = "" onClick={() => handleShow(service.title, service.description)}>{service.title}</p>)} image={card.img} />
                </div>
              )}
        </div>
        <Modal 
          show={show}
          onHide={handleClose}
           {...props}
        
           aria-labelledby="contained-modal-title-vcenter"
      centered >
            <Modal.Header closeButton>
            <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{description}</Modal.Body>
    
      </Modal>
      </div>
      
    </section>  

</>
);
}
export default DataEngineering;
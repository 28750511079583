// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Icofont from 'react-icofont';
import 'boxicons';
import Navbar from './Navbar/Navbar';
import Home from './Home/Home';
// import About from './About/About';
// import Count from './Count/Count';
import Services from './Services/Services';
// import Partners from './Partners/Partners';
// import Process from './Process/Process';
// import Clients from './Clients/Clients';
import AboutUS from './AboutUs/AboutUs';
import Footer from './Footer/Footer';
// import DataScience from './DataScience/DataScience';
import OurClients from './OurClients/OurClients';
import Technology from './Technology/Technology';
import Career from './Career/Career';






function App() {
  return (

    <div>
          
          {/* <UnderConstruction/>     */}
          <Navbar/>
          
          <Home/>
          <Services/>
          {/* <DataEngineering/>
          <DataScience/>
          <BusinessAnalytics/> */}
          <OurClients/>

          <Technology/>
          <Career/>
          <AboutUS/>
          {/* <About/> */}
          {/*<Clients/>
          <Process/>
          <About/>
          <UseCase/> 
          <Partners/>
          <Projects/>
          */}
          <Footer/>       
    </div>
  );
}

export default App;

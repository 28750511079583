import React from 'react';
import { Col, Form, Button, Card } from 'react-bootstrap';
import  './AboutUs.css';
// import CountImg from '../assets/img/banner/about.png';
import { useForm } from '@formspree/react';


const Aboutus = () => {
 
  const [state, handleSubmit] = useForm("mqknbepe");

  return (
    <>
      <section id="about" className="contact"  >
        <div className="container fluid" data-aos="fade-up">

          <div className="section-title deco"><br />
            <h2>About</h2>
          </div>
          <h5 className="section-title ">Mission Statement </h5>
          <p className="section-title "> To provide value added data insights to clients in areas of
            Cloud Data and Artificial Intelligence.</p>

          <div className="row">
            <div className="col-lg-6 align-items-stretch" >
            {state.succeeded?
                    <Card className="text-center shadow" style= {{padding:"100px"}} >
                      <div className="info" style={{padding:'2vw'}}>
                            <Card.Title>Thanks for connecting. We will get back to you shortly.</Card.Title>
                            <br/>
                      </div>
                    </Card>
                  :
              <div className="col-lg-12 align-items-stretch" >
                <Card className="text-center" style={{ width: '-webkit-fill-available' }} >
                  <div className="info" style={{ padding: '1.8vw' }}>
                    <Card.Title>Ask Us</Card.Title>
                    <Card.Text>
                      <Form onSubmit={handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridText">
                            <Form.Control type="text" name="name"  id="name" placeholder="Your Name" required='true'  />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Control type="email"  id="email" name="email" placeholder="Enter email" required='true'  />
                          </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formGridTextarea">
                          <Form.Control as="textarea" placeholder="Message"  id="message" name="message" required='true' />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                        <br />
                      </Form>
                    </Card.Text>
                  </div>
                  </Card>
                 
              
              </div>
            }
              <div className="col-lg-12 align-items-stretch">
                <Card className="text-center" style={{ width: '-webkit-fill-available' }} >
                  <div className="info" style={{ padding: '1.6vw' }}>
                    <Card.Title>Email Us </Card.Title>
                    <Card.Text>
                      <h6> info@tigervector.com </h6>
                    </Card.Text>
                  </div>
                </Card>
              </div>
              <br />
            </div>
            <br />
            <div className="col-lg-6  align-items-stretch">
              <div className="col-lg-12 align-items-stretch">
                <Card className="text-center">
                  <div className="info">
                    <Card.Title >US</Card.Title>
                    <Card.Text>
                      <h6>1108 Great Passage Blvd, Great Falls, VA 22066</h6>
                      <h6>Tel - +1 (201) 218-6010</h6>
                    </Card.Text>
                  </div>
                </Card>
              </div>
              
              <div className="col-lg-12 mt-5 mt-lg-0 align-items-stretch">
                <Card className="text-center">
                  <div className="info">
                    <Card.Title>India</Card.Title>
                    <Card.Text className="crdtext">
                      <h6><div><span style={{ lineHeight: 'revert' }}>317 B Shastri Nagar, </span> <span>Bhilwara, Rajasthan, India 311001</span></div></h6>
                      <h6>Tel - +91 8800057111</h6>
                    </Card.Text>
                  </div>
                </Card>
              </div>
              
              <div className="col-lg-12 mt-5 mt-lg-0 align-items-stretch">
                <Card className="text-center">
                  <div className="info">
                    <Card.Title>Hong Kong</Card.Title>
                    <Card.Text className="crdtext">
                      <h6>B01/4th floor, Summit Building, 30, Man Yue Street, Hunghom, Kowlnoon</h6>
                      <h6>Tel - +852 29499109(D), +852 29499239(G)</h6>
                    </Card.Text>
                  </div>
                </Card>
              </div>
              
            </div>
          </div>
        </div>

      </section>
    </>

  );
}
 export default Aboutus;
import React from 'react';
import Card from '../Card/Card';

import finance from '../assets/img/finance.png';
import insurance from '../assets/img/insurance.jpg';
// import media from '../assets/img/media2.jpeg';
// import nonprofit from '../assets/img/non_profit2.jpeg';

import media_new from '../assets/img/shutterstock_computer.jpeg';

import non_profit_new from '../assets/img/shutterstock_building.jpeg';
import './OurClients.css';



     const data = {
         "card1": {
             title : 'FINANCE',
             description : 'Profit & Loss Dashboards, Intra-day Risk Dashboard, Trade Analytics, Return Prediction',
             img : finance
         },
         "card3": {
            title : 'INSURANCE',
            description :  'Claims Data Analytics, Adjuster Efficiency Improvement, , Auto Damage Detection Models , Labor hours prediction models, Claim Estimate Automation ',
            img : insurance
        },
        "card2":  {
            title : 'MEDIA',
            description : ' Google AdManager & YouTube etc Data Sourcing, Verizon Data Sourcing, Merger Data Migration, Streaming Analytics',
            img : media_new
        },
        "card4":  {
            title : 'NON PROFIT',
            description : 'Data Platform Streamlining, Security Framework, Best Practices Implementations, Machine Learning Models',
            img : non_profit_new
        }

    }

const ourclients = () => (
<>
    <section id="clients" className="" style={{backgroundColor: "#F4F4F4"}}>
      <div className="container" >

        <div className="section-title deco"><br/><br/>
          <h2>Clients</h2>
        </div>
        <p className="section-title ">By using of our expertise in Artificial Intelligence and Machine Learning, we work on to fulfill the unmet needs of our clients. Our expertise is backed by deep research and learning in AI / ML technologies to deliver innovative yet cost effective Artificial Intelligence & Machine Learning solutions to our clients. which can easily tunes in with the business needs and provide you the detailed insights that can help the business grow. 
        </p>
          
          <div className="rowds">
          
                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0 ">
                    <Card title={data.card1.title} content={data.card1.description} image={data.card1.img} className = "card-img-top"/>
                </div>
                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0 ">
                    <Card title={data.card2.title} content={data.card2.description} image={data.card2.img} className = "card-img-top" />
                </div>         
                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 ">
                    <Card title={data.card3.title} content={data.card3.description} image={data.card3.img} className = "card-img-top" />
                </div>
                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                    <Card title={data.card4.title} content={data.card4.description} image={data.card4.img} />
                </div>           
        </div>

      </div>
      
    </section>  

</>
);
export default ourclients;
import React  from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import './Navbar.css';
// import Logo from '../assets/img/FinSharpLogo.png';


import NewLogo from '../assets/img/FinSharp_Logo.png'


// import NavBar, { ElementsWrapper } from 'react-scrolling-nav';
var Scroll = require('react-scroll');
// var scroll = Scroll.animateScroll;
var scroller = Scroll.scroller;
// scroller.scrollTo('clients' ,{
//   duration: 1500,
//   delay: 100,
//   smooth: true});
class Navigation extends React.Component {
      render() {
        return(

<>
  <header id="header" className="fixed-top header-scrolled">{/*fixed-top add in this classname*/}
    <div className="container d-flex align-items-center">
      <Navbar bg="" collapseOnSelect expand="lg">
      <Navbar.Brand href="#home"  className=" logo me-auto mt-3">
         <a href="index.html">
           <img
            src={NewLogo}
            width="58%"
            height="58%"
            className=" logo d-inline-block align-top"
            alt="Finsharp logo"
         /></a>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="nav-menu mr-auto ">
          <Nav.Link onClick={() => scroller.scrollTo('home', {
                smooth: true,
                offset: -70,
                duration: 1000,
            })}>
              Home
          </Nav.Link>
          <Nav.Link onClick={() => scroller.scrollTo('services', {
                smooth: true,
                offset: -70,
                duration: 1000,
            })}>
              Services
          </Nav.Link>
          {/* <Nav.Link onClick={() => scroller.scrollTo('dataengineering', {
                smooth: true,
                offset: -70,
                duration: 1000,
            })}> 
              DATA ENGINEERING 
            </Nav.Link>
          <Nav.Link onClick={() => scroller.scrollTo('datascience', {
                smooth: true,
                offset: -70,
                duration: 1500,
                spy: true,
            })}>
          DATA SCIENCE 
          </Nav.Link>
          <Nav.Link onClick={() => scroller.scrollTo('businessanalytics', {
                smooth: true,
                offset: -70,
                duration: 1200,
                spy: true,
                activeClass:"active"
            })}>
              BUSINESS ANALYTICS 
              </Nav.Link> */}
          <Nav.Link onClick={() => scroller.scrollTo('clients', {
                smooth: true,
                offset: -70,
                duration: 1200,
                spy: true,
                activeClass:"active"
            })}>
              CLIENTS 
              </Nav.Link>
          <Nav.Link onClick={() => scroller.scrollTo('technology', {
                smooth: true,
                offset: -70,
                duration: 1200,
                spy: true,
                activeClass:"active"
            })}>
              TECHNOLOGY
          </Nav.Link>
          <Nav.Link onClick={() => scroller.scrollTo('career', {
                smooth: true,
                offset: -70,
                duration: 1200,
                spy: true,
            })}>
              CAREER  
          </Nav.Link>
          <Nav.Link style= {{borderRightStyle: 'none'}} onClick={() => scroller.scrollTo('about', {
                smooth: true,
                offset: -70,
                duration: 1200,
                spy: true,
                activeClass:"active"
            })}>
              ABOUT 
          </Nav.Link>
        </Nav>
      </Navbar.Collapse> 
  </Navbar>
  {/*<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="#features">Features</Nav.Link>
      <Nav.Link href="#pricing">Pricing</Nav.Link>
     
    </Nav>
    <Nav>
      <Nav.Link href="#deets">More deets</Nav.Link>
      <Nav.Link eventKey={2} href="#memes">
        Dank memes
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
        </Navbar>*/}
  </div>
  </header>  
</>

          );
      }
		
	}
export default Navigation;
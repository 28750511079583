import React from 'react';
import Card from '../Card/Card';
// import AI from '../assets/img/banner/card.jpg';


     const data = {
         "card1": {
             title : 'GCP Data Architect',
             description : '15yrs of experience',
             contact : 'career@tigervector.com',
      
         },
         "card2": {
            title : 'Sr. Data Scientist',
            description :  '3yrs of experience',
            contact : 'career@tigervector.com',
           
        },
        "card3":  {
            title : 'Sr. Data Engineer ',
            description : ' 5yrs of experience',
            contact : 'career@tigervector.com',
           
        },
        "card4":  {
            title : 'Data Analyst',
            description : '3yrs of experience',
            contact : 'career@tigervector.com',
           
        },

        "card5":  {
            title : 'Data Engineer',
            description : '0-1yrs of experience',
            contact : 'career@tigervector.com',
           
        },

        "card6":  {
            title : 'Data Scientist',
            description : '0-1yrs of experience',
            contact : 'career@tigervector.com',
           
        },
        "card7":  {
            title : 'Sr. Data Scientist',
            description : '3yrs+ of experience',
            contact : 'career@tigervector.com',
           
        }

    }

const career = () => (
<>
    <section id="career" className="" style={{backgroundColor: "#F4F4F4"}}>
      <div className="container" >

        <div className="section-title deco"><br/><br/>
          <h2>Career</h2>
        </div>
        <br/>
        <h5 className="section-title ">Pursue your passion with Tiger Vector </h5>
        <p className="section-title "> If you’re a value-driven individual looking for the next step in your professional career, Tiger Vector may be the new home for you.</p>
          
          <div className="rowds" style= {{paddingTop : '23px'}}>
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                    <Card title={data.card1.title} content={data.card1.description} contact={data.card1.contact} />
                </div>
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                    <Card title={data.card2.title} content={data.card2.description} contact={data.card2.contact}/>
                </div>         
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                    <Card title={data.card3.title} content={data.card3.description} contact={data.card3.contact}/>
                </div>       
        </div><br/><br/>

        <div className="rowds" >
            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                <Card title={data.card5.title} content={data.card5.description} contact={data.card5.contact}/>
            </div>
            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                <Card title={data.card6.title} content={data.card6.description} contact={data.card6.contact}/>
            </div>         
            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <Card title={data.card4.title} content={data.card4.description} contact={data.card4.contact}/>
            </div>             
        </div> <br/><br/>

        {/* <div className="rowds" >
            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                <Card title={data.card7.title} content={data.card7.description} contact={data.card7.contact}/>
            </div>             
        </div> */}


      </div>
      
    </section>  

</>
);
export default career;
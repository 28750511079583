import React from 'react';
import Card from '../Card/Card';
// import AI from '../assets/img/banner/card.jpg';


     const data = {
         "card1": {
             title : 'Google Cloud Platform (GCP) ',
             description : ' GCS, BigQuery, BigTable, PubSub, Composer & Airflow, Security Manager, Kubernetes  GKE, StackDriver, Compute Engine, CloudSQL, Pub/Sub, DataProc, BigTable',
      
         },
         "card2": {
            title : 'AWS',
            description :  'Claims Data Analytics, Adjuster Efficiency Improvement, , Auto Damage Detection Models , Labor hours prediction models, Claim Estimate Automation ',
           
        },
        "card3":  {
            title : 'Azure',
            description : ' Google AdManager & YouTube etc Data Sourcing, Verizon Data Sourcing, Merger Data Migration, Streaming Analytics',
           
        },
        "card4":  {
            title : 'Big Data',
            description : 'Data Platform Streamlining, Security Framework, Best Practices Implementations, Machine Learning Models',
           
        },
        "card5":  {
            title : 'Machine Learning',
            description : 'TensorFlow Models, Natural Language Processing (NLP), Neural Networks (ANN, CNN, RNN), AutoML, KNN, SciKit Learn, Reinforcement learning, NumPy, SeaBorn, Pandas',
           
        },
        "card6":  {
            title : 'Business Analytics',
            description : ' DataStudio, Looker, Tableau, QlickView, MS Power BI, Crystal etc.',
           
        }
    }

const technology = () => (
<>
    <section id="technology" className=" section-bg" >
      <div className="container" >

        <div className="section-title deco"><br/><br/>
          <h2>Technology</h2>
        </div>
        <p className="section-title "> FinSharp is a team of exceptionally talented professional in below technologies, that drive growth & innovation and enable digital transformation.</p>
          
          <div className="rowds">
           
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                    <Card title={data.card1.title} content={data.card1.description} />
                </div>
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                    <Card title={data.card2.title} content={data.card2.description} image={data.card2.img}/>
                </div>         
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                    <Card title={data.card3.title} content={data.card3.description} image={data.card3.img}/>
                </div>
               </div><br/><br/>

                <div className="rowds">
                  <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                    <Card title={data.card4.title} content={data.card4.description} image={data.card3.img}/>
                </div>   
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                    <Card title={data.card5.title} content={data.card5.description} image={data.card3.img}/>
                </div>   
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                    <Card title={data.card6.title} content={data.card6.description} image={data.card3.img}/>
                </div>          
        </div>

      </div>
      
    </section>  

</>
);
export default technology;
import React from 'react';
import { Card } from 'react-bootstrap';

import './Card.css';
const card = (props) => (

        <>
            <Card style={{ width: '19vw' }} className="text-center card">
              <Card.Img variant="top" src={props.image} />
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text>
                      {props.content}
                    </Card.Text>
                    <div>{props.list}</div>
                     <Card.Link href="#">{props.contact}</Card.Link>

                
              </Card.Body>
          </Card>
        </>
);

export default card;